import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 3022.000000 2976.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2976.000000) scale(0.100000,-0.100000)" >


<path d="M19773 18048 c-162 -200 -305 -426 -713 -1133 -259 -449 -392 -673
-754 -1270 -70 -115 -163 -282 -207 -371 l-79 -160 0 -466 0 -466 133 70 c471
249 767 537 827 809 17 78 9 229 -17 301 -12 31 -18 59 -15 62 3 3 97 6 209 6
l203 0 0 -33 c0 -54 -37 -186 -75 -265 -59 -126 -152 -249 -290 -388 -145
-145 -245 -228 -412 -341 -62 -42 -112 -77 -110 -78 1 -1 43 4 92 12 194 31
436 4 567 -63 185 -94 267 -319 239 -654 -16 -192 -14 -268 9 -342 22 -72 79
-165 152 -250 33 -38 45 -59 37 -64 -7 -4 -127 -53 -268 -110 l-256 -103 -29
32 c-89 100 -155 245 -182 399 -17 95 -16 173 2 588 5 116 3 157 -9 207 -44
168 -154 231 -387 220 -130 -6 -220 -28 -341 -84 l-79 -36 0 -206 0 -207 43
-70 c127 -206 206 -294 782 -874 265 -267 536 -546 602 -620 218 -245 454
-447 536 -460 17 -3 195 -5 394 -5 200 0 363 -3 363 -6 0 -3 26 -2 58 2 71 9
335 20 549 22 114 2 166 6 174 15 7 6 21 12 31 12 10 0 18 4 18 9 0 5 22 11
49 15 27 3 54 13 61 20 6 8 29 17 51 21 21 4 39 11 39 16 0 5 8 9 18 9 9 0 49
12 87 26 39 14 78 27 88 29 20 4 1068 947 1428 1285 57 53 160 172 155 178 -3
2 4 16 14 30 11 13 20 32 20 42 0 9 3 20 7 24 4 3 7 19 8 35 1 15 12 42 24 60
13 18 21 35 17 38 -3 3 4 19 16 35 17 24 18 32 8 38 -11 7 -11 12 -2 23 7 8
12 19 10 23 -1 5 3 19 10 31 9 17 9 22 0 25 -9 3 -2 21 19 53 21 32 29 53 23
60 -6 7 -4 19 6 33 8 12 17 31 19 41 2 11 16 38 32 62 20 30 24 44 15 47 -10
3 -10 9 2 27 12 18 12 25 3 28 -9 3 -9 9 0 24 8 14 8 24 1 31 -7 7 -4 20 11
41 12 17 24 40 26 51 2 11 14 34 26 51 17 25 19 33 9 39 -11 7 -11 11 -1 24 7
8 10 20 7 25 -4 5 -2 12 4 16 6 4 8 11 4 16 -3 5 -1 16 5 23 7 8 11 18 10 22
-2 9 2 23 21 71 6 14 5 25 -1 29 -6 4 -5 13 4 27 14 22 28 67 24 77 -1 3 8 22
21 42 14 23 19 39 12 43 -7 4 -6 13 1 28 9 16 9 22 0 25 -9 3 -8 10 3 27 14
22 14 31 -10 112 -33 116 -110 270 -181 365 -56 74 -496 575 -912 1038 l-202
225 -118 67 -118 67 -289 -292 c-159 -161 -397 -402 -529 -537 -448 -458 -740
-750 -749 -750 -5 0 -1 9 9 20 10 11 19 29 20 40 1 11 14 34 30 50 27 28 28
31 12 47 -16 16 -15 19 10 45 16 17 21 28 13 28 -9 0 -8 6 6 21 14 15 15 22 6
28 -10 6 -9 11 3 21 9 7 14 19 11 26 -2 7 4 20 15 30 11 10 17 24 14 32 -3 9
6 27 21 43 26 27 26 27 4 34 -21 7 -21 8 -4 27 16 16 16 21 4 28 -8 6 -19 10
-25 10 -5 0 10 19 33 43 27 27 41 49 37 59 -3 8 1 21 10 28 14 11 14 14 -2 25
-15 11 -15 14 6 40 13 18 18 31 11 35 -6 4 0 16 15 30 13 12 22 27 19 32 -3 5
-1 15 5 22 6 7 9 20 6 28 -3 8 1 21 10 28 14 11 14 14 -2 25 -15 11 -14 15 10
46 16 22 25 44 23 62 -2 15 2 32 9 37 7 6 11 19 8 28 -2 10 5 26 16 36 19 18
20 20 4 31 -16 12 -14 17 22 60 34 43 36 48 21 57 -16 9 -16 11 0 29 15 17 15
19 0 25 -24 9 -20 24 21 66 35 36 36 38 18 52 -17 12 -18 16 -5 31 13 16 13
19 -2 29 -16 10 -15 13 9 38 23 24 24 28 10 34 -25 9 -21 24 21 67 36 37 36
39 18 53 -18 13 -19 15 -2 33 17 18 17 19 -4 26 -20 6 -21 8 -4 26 9 11 14 22
11 25 -4 4 2 19 13 34 18 25 18 29 5 40 -14 11 -14 15 1 31 15 17 15 19 0 25
-23 9 -20 19 20 64 34 37 35 42 20 53 -16 12 -16 15 4 40 12 16 21 29 19 31
-2 1 -355 200 -784 441 -429 241 -794 447 -812 457 l-31 19 -59 -73z m53
-3368 c35 -80 130 -303 211 -497 81 -194 151 -349 154 -345 6 7 368 851 405
944 l19 48 163 0 c89 0 162 -4 162 -9 0 -5 -133 -316 -296 -692 -163 -376
-298 -690 -301 -696 -4 -10 -47 -13 -168 -13 l-162 0 -50 118 c-28 64 -164
379 -302 700 -138 320 -251 585 -251 588 0 2 80 3 177 2 l177 -3 62 -145z
m1584 -70 l0 -130 135 0 135 0 0 -125 0 -125 -135 0 -135 0 0 -235 c0 -261 6
-292 59 -319 39 -21 123 -20 163 0 17 9 34 12 38 7 4 -4 24 -53 45 -107 l38
-99 -22 -18 c-55 -45 -215 -69 -327 -50 -172 30 -274 126 -303 286 -6 32 -11
166 -11 297 l0 238 -80 0 -80 0 0 125 0 125 80 0 80 0 0 130 0 130 160 0 160
0 0 -130z m830 -434 c0 -347 4 -382 53 -435 81 -88 274 -78 346 19 52 71 55
93 59 428 l4 312 159 0 159 0 0 -540 0 -540 -155 0 -155 0 0 55 0 55 -43 -33
c-220 -168 -576 -105 -691 122 -51 99 -56 147 -56 529 l0 352 160 0 160 0 0
-324z"/>
<path d="M8621 17060 c-575 -48 -1034 -224 -1437 -550 -224 -181 -450 -447
-600 -705 -95 -164 -111 -196 -162 -316 -161 -374 -235 -736 -235 -1144 0
-315 43 -579 143 -878 56 -168 201 -477 224 -477 8 0 56 -33 108 -72 52 -40
101 -77 110 -83 25 -16 127 -91 270 -200 73 -55 135 -99 138 -98 3 1 26 -14
49 -33 24 -19 48 -34 53 -34 6 0 39 -22 74 -50 35 -27 94 -74 131 -102 37 -29
63 -46 58 -38 -5 8 21 -9 58 -37 37 -29 97 -77 134 -105 37 -29 63 -46 58 -38
-5 8 15 -4 44 -27 30 -24 58 -43 63 -43 6 0 39 -23 74 -51 35 -27 66 -49 70
-48 3 1 60 -39 125 -90 65 -50 122 -91 127 -91 5 0 33 -19 63 -42 29 -24 49
-37 45 -29 -5 7 5 3 22 -9 46 -34 162 -49 432 -56 321 -9 544 18 819 97 366
105 755 319 1008 553 l63 58 52 -38 c29 -20 148 -110 263 -199 365 -281 516
-385 670 -462 227 -115 308 -116 468 -6 181 123 226 290 129 478 -78 149 -133
193 -1647 1296 -71 52 -200 150 -285 218 -373 294 -607 415 -770 397 -110 -13
-227 -87 -288 -184 -101 -158 -76 -345 64 -500 53 -58 265 -215 342 -252 60
-30 62 -33 62 -69 0 -36 -1 -38 -53 -54 -29 -9 -95 -42 -147 -73 -181 -108
-304 -138 -635 -154 -221 -11 -353 -3 -488 29 -740 176 -1301 970 -1229 1739
21 218 80 420 178 601 25 46 44 84 42 86 -2 2 -73 36 -158 77 -165 80 -375
197 -499 280 -95 64 -241 171 -238 174 2 2 35 -5 74 -15 40 -10 74 -16 77 -14
3 3 37 -2 75 -12 39 -9 85 -18 103 -19 39 -3 128 -19 254 -47 50 -11 108 -22
130 -24 21 -2 67 -10 102 -18 34 -9 60 -11 56 -6 -8 13 5 12 119 -15 147 -35
156 -33 257 49 361 293 788 437 1197 404 522 -42 971 -329 1288 -824 61 -95
73 -107 92 -100 6 2 27 0 45 -5 18 -6 57 -13 88 -16 30 -3 66 -8 80 -9 43 -6
133 -16 175 -20 100 -10 136 -16 228 -39 70 -17 97 -20 93 -11 -3 10 7 10 47
-1 45 -13 129 -25 177 -26 8 0 43 -7 77 -14 80 -18 137 -24 151 -15 8 5 3 41
-19 121 -95 356 -279 717 -520 1022 -97 122 -309 336 -429 432 -122 97 -317
225 -450 295 -273 143 -528 222 -860 266 -138 18 -524 27 -669 15z"/>
<path d="M17490 14687 c0 -1483 -1 -1536 -19 -1609 -33 -130 -123 -275 -182
-293 -64 -21 19 -25 461 -25 442 0 525 4 461 25 -33 10 -91 84 -127 159 -59
124 -64 162 -64 447 l0 256 -49 104 c-95 202 -134 370 -134 579 -1 215 48 453
137 675 l36 89 0 470 0 471 -253 93 c-138 51 -255 92 -259 92 -5 0 -8 -690 -8
-1533z"/>
<path d="M14964 15653 c-284 -189 -483 -448 -589 -767 -49 -145 -66 -257 -66
-426 0 -367 99 -657 316 -927 170 -212 456 -404 710 -478 255 -73 565 -77 805
-9 91 26 257 94 272 111 12 14 6 15 -67 7 -44 -5 -157 -9 -250 -9 -146 0 -177
3 -213 19 -71 32 -47 63 71 90 150 35 333 164 384 271 9 19 14 37 12 40 -3 2
-59 -22 -125 -55 -405 -204 -854 -142 -1181 163 -236 220 -362 555 -324 864
45 363 255 667 574 827 l87 44 -162 134 c-90 74 -168 137 -174 141 -6 3 -42
-14 -80 -40z"/>
<path d="M15740 14892 l0 -229 -47 -25 c-69 -35 -106 -99 -111 -189 -5 -85 21
-147 80 -189 119 -86 290 -45 349 85 25 54 25 145 0 194 -22 42 -80 97 -117
111 l-24 10 0 177 0 178 -65 53 -65 53 0 -229z"/>
<path d="M12510 14574 c0 -369 -4 -428 -42 -544 -78 -242 -241 -386 -516 -456
-187 -47 -355 -41 -487 20 -60 27 -135 98 -155 145 -12 30 -295 250 -540 421
-58 40 -111 78 -119 84 -11 9 -13 -25 -9 -214 5 -198 8 -234 28 -295 42 -131
121 -269 214 -372 251 -279 780 -390 1206 -252 202 66 394 212 438 334 8 23
11 -13 11 -162 l1 -193 280 0 280 0 0 499 0 500 -291 418 c-160 230 -293 420
-295 422 -2 2 -4 -158 -4 -355z"/>
<path d="M11465 14829 c-179 -42 -453 -87 -673 -110 -109 -11 -216 -22 -239
-25 l-43 -5 0 -55 c0 -122 39 -165 345 -379 77 -54 248 -180 380 -279 133
-100 262 -196 287 -213 l46 -33 6 38 c3 20 18 111 33 202 24 146 27 191 27
390 0 168 -4 255 -18 345 -10 66 -21 127 -25 136 -5 14 -19 13 -126 -12z"/>
<path d="M16514 14379 c-7 -11 -1 -138 16 -354 6 -66 14 -203 20 -305 12 -237
15 -250 49 -250 17 0 153 72 351 186 179 102 326 187 328 189 2 2 -121 106
-273 231 -190 157 -301 242 -356 272 -80 42 -122 52 -135 31z"/>
<path d="M13494 13401 c3 -68 6 -166 6 -218 l0 -93 145 0 c80 0 145 2 145 4 0
6 -279 408 -293 421 -6 7 -7 -31 -3 -114z"/>
<path d="M16248 12610 c-111 -19 -219 -97 -261 -188 -17 -37 -22 -66 -22 -137
0 -81 3 -95 30 -144 57 -103 159 -166 291 -178 76 -7 171 10 234 42 48 24 130
101 130 121 0 12 -51 54 -64 54 -4 0 -21 -16 -38 -36 -17 -19 -54 -47 -82 -62
-44 -22 -63 -26 -136 -26 -96 0 -136 13 -195 65 -96 85 -101 245 -9 331 106
99 307 97 411 -4 l32 -31 36 28 37 27 -39 40 c-48 49 -121 84 -204 97 -71 12
-84 12 -151 1z"/>
<path d="M16980 12609 c-177 -29 -301 -177 -287 -344 9 -120 76 -212 195 -268
63 -30 74 -32 177 -32 103 0 114 2 177 32 119 56 186 148 195 268 14 171 -110
315 -297 345 -74 11 -84 11 -160 -1z m227 -115 c88 -42 133 -114 133 -212 0
-85 -46 -154 -135 -200 -43 -23 -62 -26 -140 -26 -101 1 -142 14 -201 66 -48
42 -74 100 -74 166 0 61 15 103 55 150 75 89 240 115 362 56z"/>
<path d="M17520 12290 l0 -310 45 0 45 0 2 222 3 223 113 -168 c63 -92 117
-167 120 -167 4 0 57 73 117 162 l110 162 3 -217 2 -217 50 0 50 0 0 311 0
310 -42 -3 -43 -3 -117 -169 c-64 -93 -120 -172 -125 -177 -4 -4 -64 73 -132
172 l-124 179 -38 0 -39 0 0 -310z"/>
<path d="M15696 12068 c-33 -46 -7 -108 44 -108 32 0 60 31 60 65 0 34 -28 65
-60 65 -18 0 -34 -8 -44 -22z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
